import React from 'react';
import Page from '../components/layouts/Page';
import ImageSlider from '../components/molecules/ImageSlider';
import CallToAction from '../components/CallToAction';
import WoodSpacer from '../components/molecules/WoodSpacer';
import DotSpacer from '../components/molecules/DotSpacer';
import Container from '../components/layouts/Container';
import Title from '../components/type/Title';
import ContentItem from '../components/molecules/ContentItem';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import Breadcrumb from '../components/molecules/Breadcrumb';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export default function speltakItem({ data }) {
  const content = data.wpSpeltak;

  const logo = getImage(content.speltakken.logo.localFile);

  return (
    <>
      <GatsbySeo
        title={content.title}
        description="Onze jongste speltak noemen wij de bevers. Dit zijn kids tussen de 4/7 jaar die met hun leiding allerlei avonturen beleven om/in ons gebouw."
        openGraph={{
          type: 'website',
          images: [
            {
              url: content.speltakken.logo.localFile.url,
              width: 800,
              height: 600,
              alt: 'Scouting Graaf Daron',
            },
          ],
          url: 'https://graafdaron.nl/speltakken/' + content.slug,
          title: content.title + ' | Scouting Graaf Daron',
          description: content.speltakken.description,
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Home',
            item: 'https://graafdaron.nl',
          },
          {
            position: 2,
            name: 'De groep',
            item: '#',
          },
          {
            position: 3,
            name: 'Speltakken',
            item: 'https://graafdaron.nl/speltakken',
          },
          {
            position: 4,
            name: content.title,
            item: 'https://graafdaron.nl/speltakken/' + content.slug,
          },
        ]}
      />
      <Page
        breadcrumbs={
          <>
            <Breadcrumb title="Home" link="/" />
            <Breadcrumb title="De groep" link="/de-groep" />
            <Breadcrumb title="Speltakken" link="/speltakken" />
            <Breadcrumb
              title={content.title}
              link={`/speltakken/${content.slug}`}
            />
          </>
        }
        sidebar={true}
        title={content.title}
        html={
          `            <p class="md:hidden p-2 mt-4 border-2 border-solid border-red font-accent text-red md:mt-0 md:mb-16 inline-block">
              Opkomsttijden: <b>${content.speltakken.times}</b>
            </p>` + content.speltakken.content
        }
        aside={
          <div className="md:text-center">
            <p className="hidden p-2 mt-16 border-2 border-solid border-red font-accent text-red md:mt-0 md:mb-16 md:inline-block">
              Opkomsttijden: <b>{content.speltakken.times}</b>
            </p>
            <GatsbyImage
              image={logo}
              alt="logo speltak"
              className="block w-48 mt-10 md:mx-auto lg:mt-0"
            />
          </div>
        }
        stickyaside={true}
        bottom={
          <>
            {content.speltakken.showTeam && (
              <Container className={'pb-8 md:pb-16'}>
                <DotSpacer mb={true} />
                <Title
                  title={content.speltakken.secondTitle ?? 'Ons leidingteam'}
                />
                <section className="relative z-20 grid grid-cols-1 pt-10 gap-x-8 gap-y-6 md:pt-0 xl:grid-cols-2 xl:gap-x-16 xl:gap-y-12">
                  {content.speltakken.team &&
                    content.speltakken.team.map((card) => {
                      return (
                        <ContentItem
                          image={card.photo.localFile}
                          title={card.title}
                          text={card.content}
                          hideButton={true}
                        />
                      );
                    })}
                </section>
              </Container>
            )}
            <>
              {content.speltakken.callToAction?.type === 'customhtml' && (
                <>
                  <CallToAction
                    style={content.speltakken.callToAction?.type}
                    html={content.speltakken.callToAction?.customHtml}
                  />
                  <WoodSpacer small={true} />
                </>
              )}
              {content.speltakken.callToAction?.type !== 'customhtml' && (
                <>
                  <CallToAction
                    style={content.speltakken.callToAction?.type ?? 'bigtower'}
                    html={content.speltakken.callToAction?.customHtml}
                    title={content.speltakken.callToAction?.title}
                    text={content.speltakken.callToAction?.text}
                    buttontext={content.speltakken.callToAction?.button?.title}
                    buttonurl={content.speltakken.callToAction?.button?.url}
                  />
                  <WoodSpacer small={true} />
                </>
              )}
            </>
          </>
        }
      />
    </>
  );
}

export const query = graphql`
  query speltakItemQuery($slug: String) {
    wpSpeltak(slug: { eq: $slug }) {
      slug
      featuredImage {
        node {
          localFile {
            url
          }
        }
      }
      speltakken {
        description
        content
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
        }
        showTeam
        secondTitle
        team {
          content
          title
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 400)
              }
            }
          }
        }
        callToAction {
          type
          text
          title
          customHtml
          button {
            target
            title
            url
          }
        }
        times
      }
      title
      uri
    }
  }
`;
